















































































































































































































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: {}
})
export default class PartnerStatute extends Vue {
  @Prop() partners: any
  validEmail = true
  collapsePartner = { index: -1, show: false }
  docTypes = ['pasaporte', 'forma migratoria', 'cédula profesional', 'credencial para votar']
  patrimonialRegime = ['sociedad mancomunada', 'separación de bienes', 'sociedad conyugal']
  partner: any = {
    id: '',
    socio_nombre: '',
    socio_nacionalidad: '',
    socio_estado_nacimiento: '',
    socio_fecha_nacimiento: '',
    socio_estado_civil: '',
    socio_ocupacion: '',
    socio_direccion: '',
    socio_rfc: '',
    socio_curp: '',
    socio_tipo_identificacion: '',
    socio_tipo_identificacion_numero: '',
    socio_correo: '',
    socio_acciones: '',
    socio_regimen_patrimonial: '',
    socio_calidad_migratoria: '',
    pais_residencia: '',
    tax_id: '',
    tax_type: ''
  }

  created () {
    this.partner = this.$attrs.value
    for (let i = 0; i < this.company.partners.length; i++) {
      if (this.company.partners[i].id === this.partner.id) this.collapsePartner.index = i
    }
    if (this.collapsePartner.index === -1) {
      let index = 1
      Object.values(this.partners).forEach((partner: any) => {
        if (this.partner.id === partner.id) this.collapsePartner.index = index
        else index++
      })
    }
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (Number(collapseId.replace(/[^0-9.]/g, '')) === this.collapsePartner.index) this.collapsePartner.show = isJustShown
    })
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = re.test(email)
  }

  searchErrors () {
    return (this.partner.socio_nombre === '' || !this.partner.socio_nombre ||
      this.partner.socio_nacionalidad === '' || !this.partner.socio_nacionalidad ||
      this.partner.socio_estado_nacimiento === '' || !this.partner.socio_estado_nacimiento ||
      this.partner.socio_fecha_nacimiento === '' || !this.partner.socio_fecha_nacimiento ||
      this.partner.socio_estado_civil === '' || !this.partner.socio_estado_civil ||
      this.partner.socio_ocupacion === '' || !this.partner.socio_ocupacion ||
      this.partner.socio_direccion === '' || !this.partner.socio_direccion ||
      this.partner.socio_rfc === '' || !this.partner.socio_rfc ||
      this.partner.socio_curp === '' || !this.partner.socio_curp ||
      this.partner.socio_tipo_identificacion === '' || !this.partner.socio_tipo_identificacion ||
      this.partner.socio_tipo_identificacion_numero === '' || !this.partner.socio_tipo_identificacion_numero ||
      this.partner.socio_correo === '' || !this.partner.socio_correo ||
      this.partner.socio_acciones === '' || !this.partner.socio_acciones)
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
