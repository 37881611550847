import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/Company'
import CompanyTypes from '@/store/types/CompanyTypes'
import Company from '@/services/Company'

const state: State = {
  data: {
    address: '',
    business_category: '',
    business_name: '',
    capital: 0,
    commissary: '',
    company_created_on_address: null,
    company_created_on_timestamp: 0,
    company_type: '',
    council: [],
    country: '',
    created_on: '',
    documents: [],
    duration: null,
    events: [],
    has_council_shared_faculties: false,
    has_unique_administrator: false,
    id: '',
    is_external_constitutive: false,
    legal_name: '',
    legal_names: [],
    name: '',
    notary_id: 0,
    objeto_social: '',
    partners: [],
    payment: '',
    public_property_registry: null,
    rfc: '',
    share_series: [],
    stages: [],
    state: '',
    user: { full_name: '' },
    user_id: 0,
    zip_code: '',
    address_identity: { document_url: null },
    fiscal_identity: { document_url: null },
    articles_of_incorporation: { document_url: null },
    articles_of_incorporation_render: { document_url: null },
    legal_representative_address_rfc: { document_url: null },
    legal_name_authorization: { document_url: null },
    commissary_national_identity: { document_url: null },
    cfi_Billing: { document_url: null },
    commissary_CSF: { document_url: null },
    rfcDetail: null
  }
}

function formattedDataPartners () {
  state.data.partners = state.data.partners.map((partner: any) => {
    const nationalIdentity = getDocumentPartnerByType(partner, 'national_identity', 'Identificación oficial frente')
    if (nationalIdentity.document_url) {
      partner.national_identity = nationalIdentity
      partner.national_identity_back = getDocumentPartnerByType(partner, 'national_identity_back', 'Identificación oficial reverso')
    }
    const passport = getDocumentPartnerByType(partner, 'passport', 'Pasaporte')
    if (passport.document_url) partner.passport = passport
    partner.address_identity = getDocumentPartnerByType(partner, 'address', 'Comprobante de domicilio')
    partner.cfi = getDocumentPartnerByType(partner, 'CFI', 'Cedula de Identificacion Fiscal')
    partner.id_spouse = getDocumentPartnerByType(partner, 'id_spouse', 'identificación del cónyuge')
    partner.marriage_certificate = getDocumentPartnerByType(partner, 'marriage_certificate', 'acta de matrimonio')
    if (partner.is_moral_person) {
      partner.articles_of_incorporation = getDocumentPartnerByType(partner, 'articles_of_incorporation', 'Acta constitutiva')
      partner.legal_representative_proof = getDocumentPartnerByType(partner, 'legal_representative_proof', 'Poder del rep. legal')
      partner.fiscal_identity = getDocumentPartnerByType(partner, 'fiscal_identity', 'Cedula fiscal')
    }
    return partner
  })
}

function formattedDataCompany () {
  state.data.articles_of_incorporation = getDocumentCompanyByType('articles_of_incorporation', 'articles of incorporation')
  state.data.articles_of_incorporation_render = getDocumentCompanyByType('articles_of_incorporation_render', 'articles of incorporation render')
  state.data.legal_representative_address_rfc = getDocumentCompanyByType('legal_representative_address_rfc', 'Comprobante de domicilio')
  state.data.fiscal_identity = getDocumentCompanyByType('fiscal_identity', 'Idenficiacion oficial')
  state.data.legal_name_authorization = getDocumentCompanyByApproved('legal_name_authorization', 'Autorización de denominación')
  state.data.commissary_national_identity = getDocumentCompanyByType('commissary_national_identity', 'Identificación del comisario')
  state.data.cfi_Billing = getDocumentCompanyByType('CFI_Billing', 'CIF de la facturación')
  state.data.commissary_CSF = getDocumentCompanyByType('commissary_CSF', 'Constancia de situación Fiscal')
}

function getDocumentCompanyByApproved (type: string, label: string) {
  const findDocument = state.data.documents.find((document: any) => document.document_type === type && document.is_approved)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      owner_category: findDocument.owner_category,
      id: findDocument.id,
      label,
      type,
      company_id: state.data.id
    }
  }
  return { document_url: null, type, label }
}

function getDocumentCompanyByType (type: string, label: string) {
  const findDocument = state.data.documents.find((document: any) => document.document_type === type)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      owner_category: findDocument.owner_category,
      id: findDocument.id,
      label,
      type,
      company_id: state.data.id
    }
  }
  return { document_url: null, type, label }
}

function getDocumentPartnerByType (partner: any, type: string, label: string) {
  const findDocument = partner.documents.find((document: any) => document.document_type === type)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      id: findDocument.id,
      owner_category: findDocument.owner_category,
      label,
      type,
      partner,
      company_id: state.data.id
    }
  }
  return { document_url: null, type, label, partner }
}

const getters: GetterTree<State, any> = {
  [CompanyTypes.getters.GET_DATA]: state => state.data
}

const mutations: MutationTree<State> = {
  [CompanyTypes.mutations.UPDATE_DATA]: async (stateMut, payload: State) => {
    state.data = payload.data
    formattedDataCompany()
    formattedDataPartners()
  }
}

const actions: ActionTree<State, any> = {
  [CompanyTypes.actions.UPDATE_DATA]: async ({ commit }, companyId: string) => {
    const [err, response] = await Company.getProfileCompanyById(companyId)
    if (err) return [err]
    await commit(CompanyTypes.mutations.UPDATE_DATA, response.data)
    return [null, response.data.data]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
