




















































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Components
import ViewFile from '@/components/user/companyDetail/ViewFile.vue'
import ModalEditIncorporation from '@/components/user/companyDetail/ModalEditIncorporation.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
  components: { ViewFile, ModalEditIncorporation }
})
export default class CompanyInfo extends Vue {
  editCompany = { showModal: false }
  documentsApproved = false
  IDWetrust = ''
  completeFirms = false
  showModalResendEmails = false

  mounted () {
    this.documentsApproved = this.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    this.completeFirms = this.company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  async resendEmails () {
    this.loading(true)
    const [error] = await Company.sendInvitations(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.loading(false)
    this.showModalResendEmails = false
    this.$toasted.global.success({ message: 'Se reenviaron los emails con éxito' })
  }

  async saveRender () {
    this.loading(true)
    const [error] = await Company.getRenderConstitutive(this.company.id, true)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const [error2] = await Company.endEdit(this.company.id)
    if (error2) {
      this.loading(false)
      return this.$toasted.global.error(error2)
    }
    const [err] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (err) return this.$toasted.global.error(err)
    this.$toasted.global.success({ message: 'Se genero el acta renderizada con éxito' })
  }

  async sendToWeetrust () {
    this.loading(true)
    const [error] = await Company.setWeetrust(this.company.id, this.IDWetrust)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const [error1] = await Company.updateStageByCompanyId(this.company.id, 'company_articles_mock')
    if (error1) {
      this.loading(false)
      return this.$toasted.global.error(error1)
    }
    const [error2] = await Company.updateStageByCompanyId(this.company.id, 'company_articles_mock_approved')
    if (error2) {
      this.loading(false)
      return this.$toasted.global.error(error2)
    }
    const [err] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (err) return this.$toasted.global.error(err)
    this.$toasted.global.success({ message: 'Se mando el acta a Wetrust con éxito' })
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
